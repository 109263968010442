import { classNames } from 'primereact/utils';
import React, { FC } from 'react';

import styles from './buttonPrimary.module.scss';

interface IButtonPrimary {
  onClick?: () => void;
  type?: 'button' | 'submit';
  label?: string;
  styleType?: 'default' | 'primaryOutline' | 'secondaryOutline';
  disabled?: boolean;
  iconClassName?: string;
  isDanger?: boolean;
  isLoading?: boolean;
  className?: string;
  style?: Record<string, string>;
}
const ButtonPrimary: FC<IButtonPrimary> = ({
  type = 'button',
  label = '',
  styleType = 'default',
  disabled,
  onClick,
  iconClassName,
  isDanger,
  isLoading,
  className,
  style = {},
}) => {
  return (
    <button
      type={type}
      style={style}
      className={classNames(styles.buttonPrimary, className, {
        [styles.default]: styleType === 'default',
        [styles.primaryOutline]: styleType === 'primaryOutline',
        [styles.secondaryOutline]: styleType === 'secondaryOutline',
        [styles.danger]: isDanger,
      })}
      disabled={disabled}
      onClick={disabled || !onClick ? () => null : onClick}
    >
      {iconClassName && !isLoading && (
        <i
          data-attr={iconClassName}
          className={classNames(styles.iconHolder, iconClassName)}
        ></i>
      )}
      {isLoading && <span className={classNames(styles.spinnerHolder)}></span>}
      {label && <p className={styles.label}>{label}</p>}
    </button>
  );
};

export default ButtonPrimary;
