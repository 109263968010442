export interface FilterParams {
    from?: string;
    to?: string;
    statuses?: string[];
    size?: number;
    page?: number;
    sort?: string[];
    query?: string;
    companyName?: string;
    signWellViewed?: string;
    paid?: string;
}

export interface FilterQueryParams
    extends Omit<FilterParams, "statuses" | "sort"> {
    statuses?: string;
    sort?: string;
    grantDateStart: string;
    grantDateEnd: string;
    signWellViewed: string;
    paid: string;
}

export enum DocumentStatus {
    PARTIALLY_SIGNED = "PARTIALLY_SIGNED",
    MAILED = "MAILED",
    DELIVERED = "DELIVERED",
    ACKNOWLEDGED = "ACKNOWLEDGED",
    FULLY_SIGNED = "FULLY_SIGNED",
    PENDING = 'PENDING',
}

export interface Document {
    purchaserName: string;
    purchaserEmail: string;
    orderNumber: string;
    trackingNumber: string | null;
    statuses: Record<DocumentStatus, string | null>;
    createdDateTime: string;
    id: number;
    deliveryService?: string;
}
