import {Button} from "primereact/button";
import styles from "./filters.module.scss";
import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {useMutation, useQuery} from "@tanstack/react-query";
import {DocumentService} from "../../../DocumentService";
import {useToast} from "../../../../../hooks/useToast";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../../helpers/extractErrorMessage";
import {DocumentStatus, FilterParams} from "../../../types";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {documentStatusesLabels} from "../../../../../config/config";
import {Dropdown} from 'primereact/dropdown';
import {classNames} from "primereact/utils";

const statusOptions = [
    {
        label: documentStatusesLabels[DocumentStatus.ACKNOWLEDGED],
        value: DocumentStatus.ACKNOWLEDGED,
    },
    {
        label: documentStatusesLabels[DocumentStatus.DELIVERED],
        value: DocumentStatus.DELIVERED,
    },
    {
        label: documentStatusesLabels[DocumentStatus.MAILED],
        value: DocumentStatus.MAILED,
    },
    {
        label: documentStatusesLabels[DocumentStatus.PARTIALLY_SIGNED],
        value: DocumentStatus.PARTIALLY_SIGNED,
    },
    {
        label: documentStatusesLabels[DocumentStatus.FULLY_SIGNED],
        value: DocumentStatus.FULLY_SIGNED,
    },
];

enum optionsEnum {
    paid = 'paid',
    viewed = 'viewed',
}

const options = [
    {
        label: 'Paid',
        value: optionsEnum.paid,
    },
    {
        label: 'Viewed',
        value: optionsEnum.viewed,
    },
];

type PropTypes = {
    filters: FilterParams;
    setFilters: Dispatch<
        SetStateAction<{
            from: string;
            to: string;
            statuses: string[];
            size: number;
            page: number;
            sort: string[];
            query: string;
            companyName: string;
            signWellViewed: string;
            paid: string;
        }>
    >;
    setQuery: (query: string) => void;
    toggleBulkMode: () => void;
    bulkSelectMode: boolean;
    bulkSelectedIds: number[];
    generateFedexLabels: () => void;
    labelsExportingLoading: boolean;
    currentDocumentsCount: number;
    updateSelectedIds: React.Dispatch<React.SetStateAction<number[]>>;
    loadedDocumentsIds: number[];
};

const convertToDate = (date?: Date) =>
    date ? date.toISOString()?.split("T")[0] : "";

export const Filters = ({
filters,
setFilters,
setQuery,
toggleBulkMode,
bulkSelectMode,
bulkSelectedIds,
generateFedexLabels,
labelsExportingLoading,
currentDocumentsCount,
updateSelectedIds,
loadedDocumentsIds,
}: PropTypes) => {
    const [dates, setDates] = useState<string | Date | Date[] | null | undefined>(
        null
    );
    const {show} = useToast();
    const selectedIdsLength = bulkSelectedIds.length;

    const {data: companyNamesResponse, isLoading: companyNamesLoading} = useQuery({
        queryKey: ['company-names'],
        queryFn: DocumentService.fetchCompanyNames,
        gcTime: 0,
    });
    const companyNames = companyNamesResponse?.data || [];
    const companyNamesAsOptions = companyNames.map((companyName: string) => {
        return {label: companyName, value: companyName}
    });
    const downloadCSVObj = useMutation({
        mutationFn: DocumentService.documents.downloadCSV,
        onSuccess: ({data: csvStr}) => {
            const blob = new Blob([csvStr], {type: "text/csv"});
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "data.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onError: (error: AxiosError<{ message: string }>, variables) => {
            const errorData = {
                error: error,
                variables: variables,
                retryFn: downloadCSVObj.mutate,
                show: show,
            };
            extractErrorMessage(errorData);
        },
    });
    const {mutate: downloadCSV, isPending} = downloadCSVObj;

    const exportCSV = () => {
        downloadCSV({
            ...filters,
            statuses: filters.statuses?.join(","),
            sort: filters.sort?.join(","),
            grantDateStart: filters.from || "",
            grantDateEnd: filters.to || "",
            signWellViewed: filters.signWellViewed === 'YES' ? filters.signWellViewed : 'NONE',
            paid: filters.paid === 'YES' ? filters.paid : 'NONE',
        });
    };

    const handleStatusFilterChange = (event: MultiSelectChangeEvent) => {
        event.preventDefault();
        setFilters((prev) => ({...prev, statuses: event.value}));
    };

    const handleCalendarChange = (event: CalendarChangeEvent) => {
        setDates(event.value);
    };

    useEffect(() => {
        if (Array.isArray(dates) && dates[0] && dates[1]) {
            setFilters((prev) => ({
                ...prev,
                from: convertToDate(dates?.[0]),
                to: convertToDate(dates?.[1]),
            }));
        }
    }, [dates]);
    const dropdownStateValue = useMemo(
        () => {
            const result = [];
            if (filters.paid === 'YES') {
                result.push(optionsEnum.paid);
            }
            if (filters.signWellViewed === 'YES') {
                result.push(optionsEnum.viewed);
            }
            return result;
        }, [filters]
    );
    const onSelectAll = () => {
        updateSelectedIds(loadedDocumentsIds);
    }
    const onDeselectAll = () => {
        updateSelectedIds([]);
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.filters}>
                    <div className={styles.fields}>
                        <MultiSelect
                            style={{minWidth: '180px'}}
                            value={dropdownStateValue}
                            placeholder="Select options"
                            options={options}
                            onChange={(newValueObj: MultiSelectChangeEvent) => {
                                newValueObj.preventDefault();
                                const newObj = {
                                    paid: 'NONE',
                                    signWellViewed: 'NONE',
                                };
                                const newValue = newValueObj?.value || [];
                                const isPaidIncludes = newValue.find((vl: string) => vl === optionsEnum.paid);
                                const isViewed = newValue.find((vl: string) => vl === optionsEnum.viewed);
                                if (isPaidIncludes) {
                                    newObj.paid = 'YES';
                                }
                                if (isViewed) {
                                    newObj.signWellViewed = 'YES';
                                }
                                setFilters((prev) => {
                                    return ({
                                        ...prev,
                                        ...newObj,
                                    })
                                });
                            }}
                        />
                        <Dropdown
                            style={{minWidth: '180px'}}
                            disabled={companyNamesLoading}
                            options={companyNamesAsOptions}
                            onChange={(newValueObj) => {
                                setFilters((prev) => {
                                    return ({
                                        ...prev,
                                        companyName: newValueObj.value,
                                    })
                                });
                            }}
                            placeholder="Search by company"
                            value={filters?.companyName}
                        />
                        <Calendar
                            value={dates}
                            onChange={handleCalendarChange}
                            placeholder="Pick range"
                            selectionMode="range"
                            readOnlyInput
                            //TODO Uncomment when primereact will be updated to v10
                            // hideOnRangeSelection
                        />
                        <InputText
                            placeholder="Type name"
                            value={filters.query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <MultiSelect
                            value={filters.statuses}
                            placeholder="Select status"
                            options={statusOptions}
                            onChange={handleStatusFilterChange}
                        />
                    </div>
                    <Button
                        text
                        label="Clear filters"
                        severity="secondary"
                        onClick={() => {
                            setFilters({
                                from: "",
                                to: "",
                                statuses: [],
                                size: 10,
                                page: 0,
                                sort: [],
                                query: "",
                                companyName: '',
                                signWellViewed: 'NO',
                                paid: 'NO',
                            });
                            setDates(null);
                        }}
                    />
                </div>
                <div className={styles.buttonsWrapper}>
                    <div className={styles.buttons}>
                        {
                            bulkSelectMode && (
                                <Button
                                    label="Export labels"
                                    icon="pi pi-upload"
                                    className="p-button-help"
                                    onClick={generateFedexLabels}
                                    disabled={selectedIdsLength === 0 || labelsExportingLoading}
                                />
                            )
                        }
                        <Button
                            label="Export"
                            icon="pi pi-upload"
                            className="p-button-help"
                            onClick={exportCSV}
                            disabled={isPending}
                        />
                        <button className={classNames(styles.bulkSelectModeBtn, {
                            [styles.btnActive]: bulkSelectMode,
                        })} onClick={toggleBulkMode}>
                            <i className="bi bi-pencil-square"></i>
                        </button>
                    </div>
                </div>
            </div>
            {
                bulkSelectMode && (
                    <div className={styles.bottomControl}>
                        <p className={styles.btnsLabel}>{`Selected ${selectedIdsLength} items from ${currentDocumentsCount}`}</p>
                        <div className={styles.optionBtns}>
                            <button type="button" onClick={onSelectAll}>
                                Select all
                            </button>
                            <button type="button" onClick={onDeselectAll}>
                                Deselect all
                            </button>
                        </div>
                    </div>
                )
            }
        </>
    );
};
